@import '../../../uikit/colors.scss';
@import '../../../uikit/mixins.scss';

.container {
    flex-shrink: 0;
    background-color: $black-100;
    border-radius: 50px;
    padding: 4px;
    display: inline-flex;
    flex-direction: row;
    gap: 5px;
    flex-wrap: nowrap;
    justify-content: stretch;
    height: 40px;
    position: relative;

    &.fullWidth {
        width: 100%;
    }

    &.whiteBackground {
        background-color: $black-0;
    }

    .optionBackground {
        position: absolute;
        top: 2px;
        left: 0;
        height: calc(100% - 4px);
        width: 100px;
        border-radius: 50px;

        transition: all .06s ease-in-out;
        will-change: width, transform;
        background-color: $black-900;

        &.hasHoverOffset {
            top: 4px;
            height: calc(100% - 8px);
            transition: all .2s ease;
        }
    }

    &.primaryColor .optionBackground {
        background-color: $primary;
    }

    .option {
        transition: all .15s ease-in-out;

        flex-grow: 1;
        flex-shrink: 1;
        &.noHint {
            flex-shrink: 0;
        }
        @include font(14px, 400, 16px);
        cursor: pointer;
        padding: 8px 16px;
        border-radius: 50px;
        text-align: center;
        position: relative;

        @include mobile {
            padding: 8px;
        }

        .icon {
            width: 20px;
        }

        .icon svg {
            height: 20px;
            width: 20px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    &:not(.primaryColor) .option.selected {
        color: $black-0;

        .icon {
            &.table,
            &.barChart,
            &.barChartWithLine,
            &.grid {
                svg path {
                    stroke: $black-0;
                }
            }
            &.map {
                svg path {
                    fill: $black-0;
                }
            }
        }
    }
}