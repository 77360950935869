.loader {
    width: 40px;
    height: 40px;
    opacity: 0.8;

    &.fullScreen {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
}