@import '../../../uikit/colors.scss';

@keyframes loading {
  0% {
    transform: translateX(-100%);
  }
    100% {
        transform: translateX(200%);
    }
}

.content {
    flex: 1 1 auto;
    position: relative;

    &.scroll {
        overflow: hidden;
    }

    .fullscreenButton {
        position: absolute;
        right: 10px;
        top: 10px;
        z-index: 350;
        height: 40px;
        width: 40px;
        border-radius: 20px;
        box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
    }

    &.isFullscreen {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 300;
    }

    .fetchingLoader {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 3px;
        overflow: hidden;
        z-index: 10000;

        .line {
            background-color: $primary;
            height: 100%;
            width: 50%;
            animation: loading 1s ease-in-out infinite;
            will-change: transform;
        }
    }
}

.loader {
    display: flex;
    justify-content: center;
    padding: 50px 50px 20px 50px;
}

.percent {
    font-size: 20px;
    color: $black-600;
    text-align: center;
}

.notFound {
    font-size: 24px;
    padding: 20px;
    text-align: center;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    gap: 16px;
    height: 100%;
    display: flex;
    color: $black-600;
}

.devErrorImage {
    position: fixed;
    right: 80px;
    width: 200px;
    height: 200px;
    bottom: -10px;
    transform: scaleX(-1);
    user-select: none;
    -webkit-user-drag: none;
    opacity: 1;
    transition: opacity 0.3s ease-in-out;
    &:hover {
        opacity: 0.1;
    }
}