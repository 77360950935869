@import './colors.scss';

@mixin transition($properties...) {
    transition-duration: 0.3s;
    transition-property: $properties;
    transition-timing-function: ease-in-out;

    will-change: $properties;
}

@mixin fastTransition($properties...) {
    @include transition($properties...);
    transition-duration: 0.1s;
}

@mixin scrollbars() {
    overflow: auto;
    
    &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
    }

    &::-webkit-scrollbar-thumb{
        border-radius: 10px;
        background: rgba(0,0,0,0.1);
    }
}

@mixin font($size: false, $weight: false, $lineHeight: false) {
    font-family: 'Rubik', sans-serif;
    color: $black-900;
    @if $size {
        font-size: $size;
    }
    @if $weight {
        font-weight: $weight;
    }
    @if $lineHeight {
        line-height: $lineHeight;
    }

    a {
        color: $primaryDark;
    }
}

@mixin border-radius() {
    border-radius: 32px;
}

@keyframes fadeInFromTop {
    0% {
        opacity: 0;
        margin-top: -10px;
    }
    100% {
        opacity: 1;
        margin-top: 0;
    }
}

@mixin fadeInFromTopAnimation($duration: 0.3s) {
    animation: fadeInFromTop $duration ease-in-out;
}

$middleBreakpoint: 1240px;
$smallBreakpoint: 700px;
$mobileBreakpoint: 500px;

@mixin large {
    @media (min-width: $middleBreakpoint) {
        @content;
    }
}

@mixin middle {
    @media (max-width: $middleBreakpoint) {
        @content;
    }
}

@mixin small {
    @media (max-width: $smallBreakpoint) {
        @content;
    }
}

@mixin mobile {
    @media (max-width: $mobileBreakpoint) {
        @content;
    }
}

@mixin fullHeight() {
    height: 100vh;
    height: -webkit-fill-available;
    height: -moz-available;
    height: fill-available;
    height: 100dvh;
}

@mixin fullMaxHeight() {
    max-height: 100vh;
    max-height: -webkit-fill-available;
    max-height: -moz-available;
    max-height: fill-available;
    max-height: 100dvh;
}