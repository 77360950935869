@import '../mixins.scss';
@import '../colors.scss';

.checkbox {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    align-items: flex-start;

    &.colored,
    &.alignCenter {
        align-items: center;
    }

    .icon {
        width: 24px;
        height: 24px;
        margin-right: 4px;

        background-image: url('./unchecked.svg');
        background-repeat: no-repeat;
        background-position: center;
        flex-shrink: 0;
    
        &.checked {
            background-image: url('./checked.svg');
        }
    
        &.radio {
            background-image: url('./radioUnchecked.svg');
            &.checked {
                background-image: url('./radioChecked.svg');
            }
        }

        &.colored {
            background-image: none;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .label {
        @include font(13px, 400);
        color: #505050;
    }

    .input {
        position: absolute;
        opacity: 0;
        z-index: -1;
    }
}