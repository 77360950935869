@import "../../../../../uikit/mixins.scss";

.viewFeatureSelect,
.viewSelectElements {
    display: contents;

    @include middle {
        display: flex;
        gap: 16px;
        flex-direction: column-reverse;
    }
}

.rightTitleRow {
    display: flex;
    gap: 16px;
    align-items: center;
}

.portalTargetsGroup {
    @include middle {
        width: 100%;
        height: 40px;
        justify-content: center;
    }
}

.portalTarget {
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;

    display: flex;
    gap: 16px;
    align-items: center;
    justify-content: end;
}

.portalTargetsGroup {
    position: relative;
}

.viewComponent {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
}

.portalTarget,
.viewComponent {
    opacity: 0;
    pointer-events: none;

    @include transition(opacity, transform);

    &.active {
        opacity: 1;
        pointer-events: all;
    }

    &.toTheLeft {
        transform: translateX(-150px);
    }

    &.toTheRight {
        transform: translateX(150px);
    }
}