@import '../../../../../uikit/mixins.scss';

.loadingContainer {
    background: #FFFFFF;
    border-radius: 20px;
    padding: 24px 32px;

    .title {
        @include font(20px, 700);
    }

    .body {
        margin-top: 16px;
        @include font(16px, 400);
        min-width: 300px;
    }
}