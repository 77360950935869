@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');
* {
	font-family: 'Open Sans', sans-serif;
}

.node rect {
  cursor: move;
  fill-opacity: .9;
  shape-rendering: crispEdges;
}

.node text {
  pointer-events: none;
  text-shadow: 0 1px 0 #fff;
  font-size: 16px;
}

.link {
  fill: none;
  stroke: #000;
  stroke-opacity: .2;
  fill-opacity: .2;
}

.link:hover {
  stroke-opacity: .5 !important;
  fill-opacity: .5 !important;
}

/* body {
    position:fixed;
    overflow: hidden;
    overflow-y: scroll;
    width: 100%;
}
#sankey_frame {
	border: 1px solid #ccc;
	padding: 0px;
}
#drop-area {
  border: 2px dashed #ccc;
  border-radius: 20px;
  width: 580px;
  margin: 100px auto;
  padding: 20px;
  display: none;
}
#drop-area.highlight {
  border-color: purple;
}

#panel-area {
	display: none;
	text-align: right;
	width: 100%;
}
#align-area {
	display: inline-block;
}
#measure-area {
	display: inline-block;
}
p {
  margin-top: 0;
}
.my-form {
  margin-bottom: 10px;
}
#gallery {
  margin-top: 10px;
}
#gallery img {
  width: 150px;
  margin-bottom: 10px;
  margin-right: 10px;
  vertical-align: middle;
}
.button {
  display: inline-block;
  padding: 10px;
  background: #ccc;
  cursor: pointer;
  border-radius: 5px;
  border: 1px solid #ccc;
}
.button:hover {
  background: #ddd;
}
#fileElem {
  display: none;
} */
