@import '../../../../../../../uikit/colors.scss';
@import '../../../../../../../uikit/mixins.scss';

.table {
    display: grid;
    grid-template-columns: auto 1fr 1fr;
    column-gap: 4px;
}

.header,
.section {
    display: contents;
}

.sectionLabel {
    font-size: 12px;
    color: $black-900;

    display: flex;
    align-items: center;
    width: 86px;
    margin-right: 23px;
}

.column {
    @include font(14px, 400);
    color: $black-600;
    padding: 9px 12px 9px 9px;

    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
}

.dataItem {
    @include font(14px, 400);

    display: flex;
    flex-direction: column;
    gap: 5px;

    padding: 12px 9px;

    position: relative;
}

.column, 
.dataItem {
    background: #F9F9F9;
}

.section.last .dataItem {
    border-bottom-right-radius: 12px;
    border-bottom-left-radius: 12px;
}