$black-900: #353535;
$black-800: #505050;
$black-700: #757575;
$black-600: #909090;
$black-500: #AAAAAA;
$black-400: #C0C0C0;
$black-300: #DADADA;
$black-200: #E5E5E5;
$black-100: #F0F0F0;
$black-0: #FFFFFF;

$primary: #FFDF2A;
$primaryGradient: linear-gradient(180deg, #FFDC11 0%, #FFF125 100%);
$primaryLight: #FFF9D6;
$primaryDark: #C2A30A;

$error: #DA2E0B;

$pageBackground: #E0E0E0;