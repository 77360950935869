@import '../../../uikit/colors.scss';

.more {
    cursor: pointer;
    background-image: url('../../../shared/icons/more.svg');
    height: 24px;
    width: 24px;
}

.downloadLink {
    background-image: url('../../../shared/icons/download.svg');
    width: 24px;
    height: 24px;
    cursor: pointer;
    display: inline-block;
}

.placeholder {
    a {
        color: $primaryDark;
    }
}