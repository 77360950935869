@import '../../../uikit/colors.scss';
@import '../../../uikit/mixins.scss';

@mixin subLarge {
    @media (max-width: 1000px) and (min-width: $smallBreakpoint) {
        @content;
    }
}

.header {
    z-index: 700;
    background-color: $black-0;
    padding: 12px 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: sticky;
    top: 0;
    border-bottom: 1px solid $black-200;

    @include middle {
        padding: 12px 24px;
    }

    @include small {
        padding: 12px 16px;
    }

    &.isFullscreen {
        visibility: hidden;
    }

    .spacer {
        flex: 1 1 auto;
    }
}

.mobileMenu {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    background: #fff;
    z-index: 2000;

    padding: 6px 20px 10px 64px;

    pointer-events: none;
    opacity: 0;
    transform: translateY(-30px);
    @include transition(opacity, transform);

    &.opened {
        pointer-events: all;
        opacity: 1;
        transform: translateY(0);
    }

    .menuItem {
        @include font();
        white-space: nowrap;
        text-decoration: none;
        padding: 12px 0 7px 0;
        display: block;

        &::after {
            content: '';
            display: block;
            width: 0%;
            height: 2px;
            background-color: $black-900;
            @include fastTransition(width);
            will-change: width;
            margin-top: 5px;
        }

        &.active::after {
            width: 100%;
            background-color: $primary;
        }
    }
}

.logo {
    background-image: url('./logo.svg');
    width: 102px;
    height: 32px;

    flex: 0 0 102px;

    cursor: pointer;

    @include subLarge {
        display: none;
    }
}

.mobileMenuButton,
.mobileMenuButtonSpacer {
    display: none;

    @include small {
        display: block;
        width: 32px;

        margin-right: 16px;
    }
}
     
@include small {   
    .mobileMenuButton {
        position: fixed;
        top: 16px;
        left: 16px;
        z-index: 2001;
    }
}

.menu {
    display: flex;
    gap: 48px;
    padding: 0 16px;

    @include middle {
        gap: 24px;
    }

    @include small {
        display: none;
    }

    .menuItem {
        @include font();
        white-space: nowrap;
        text-decoration: none;

        &::after {
            content: '';
            display: block;
            width: 0%;
            height: 2px;
            background-color: $black-900;
            @include fastTransition(width);
        } 
        
        &:hover {
            text-decoration: none;
            &::after {
                width: 100%;
            }
        }

        &.active::after {
            width: 100%;
            background-color: $primary;
        }
    }
}

.divider {
    width: 1px;
    height: 32px;
    background-color: $black-400;
    margin: 0 16px;
}

.help {
    padding: 8px;
    cursor: pointer;
}

.profile {
    flex: 0 0 auto;

    display: flex;
    align-items: center;
    gap: 8px;

    cursor: pointer;

    .profileName {
        @include mobile {
            display: none;
        }
    }

    .profileArrow {
        @include subLarge {
            display: none;
        }

        &.opened {
            transform: rotate(180deg);
        }

        @include mobile {
            display: none;
        }
    }
}