.loaderContainer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    display: none;
    justify-content: center;
    align-items: center;

    background: #00000042;
    z-index: 1000;
}