@import '../../../uikit/mixins.scss';
@import '../../../uikit/colors.scss';

.hint {
    color: $black-600;
}

.tableContainer {
    position: relative;
    height: calc(100% - 64px);
}

.createButtonContainer {
    padding: 16px;
    display: flex;
    justify-content: end;
}

.headerTitleLine {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include mobile {
        width: calc(100vw - 32px);
    }
}

.header {
    display: flex;
    flex-direction: row;
    justify-content: right;
    align-items: center;

    @include mobile {
        align-items: flex-start;
        width: calc(100vw - 32px);
    }

    gap: 16px;

    @include small {
        flex-wrap: wrap;
    }

    .searchInput {
        width: 577px;
        max-width: 100%;

        @include small {
            width: 100%;
        }
    }
}

.active {
    @include font(16px, 500);
    color: #69B245;
}

.notActive {
    @include font(16px, 500);
    color: #AF0007;
}

.more {
    cursor: pointer;
    background-image: url('../../../shared/icons/more.svg');
    height: 24px;
    width: 24px;
}