@import '../../../uikit/colors.scss';
@import '../../../uikit/mixins.scss';

.container {
    display: flex;
    align-items: stretch;
    height: 100%;
}

.filtersListCollapsed {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    padding-top: 11px;

    .filterItem {
        width: 64px;
        height: 57px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        background-color: $black-0;
        &:hover {
            background-color: $black-100;
        }

        position: relative;
        &.hasSelected::after {
            content: '';
            position: absolute;
            top: 16px;
            right: 16px;
            height: 8px;
            width: 8px;
            border-radius: 4px;
            background-color: $primary;
        }
    }
}

.filtersListExpanded {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;

    @include mobile {
        width: 100vw;
    }

    display: flex;
    flex-direction: column;

    position: relative;

    .list {
        display: flex;
        flex-direction: column;

        flex-grow: 1;
        @include scrollbars();
        max-height: calc(100vh - 154px);
        max-height: calc(100dvh - 154px);
    }

    .tags {
        display: flex;
        gap: 4px;
        margin-right: 12px;

        .tag {
            @include font(12px, 400);
            padding: 4px 10px;
            background: rgba(255, 223, 42, 0.4);
            border-radius: 100px;
            white-space: nowrap;
        }
    }

    .buttons {
        padding: 20px;
        display: flex;
        gap: 4px;
    }

    .item {
        border-bottom: 1px solid $black-100;

        .content {
            padding: 12px 20px;
        }

        .head {
            height: 56px;
            display: flex;
            align-items: center;
            padding: 12px 20px;
            gap: 12px;

            cursor: pointer;
            background-color: $black-0;
            &:hover {
                background-color: $black-100;
            }

            .icon {
                display: flex;
                align-items: center;
                margin-right: 11px;
            }

            .label {
                white-space: nowrap;
                @include font(16px, 400);
                flex-grow: 1;

                .required {
                    color: $error;
                    margin-left: 3px;
                }
            }

            .expand {
                width: 24px;
                height: 24px;
                
                transform-origin: center;
                transform: rotate(180deg);
                &.expanded {
                    transform: rotate(0);
                }

                background-image: url('../../../shared/icons/expandFilter.svg');
            }
        }

        &.expanded.shouldGrow {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
        }
    }
    .header {
        .head {
            @include font(24px, 600);
            cursor: default;
            margin-top: 12px;
            &:hover {
                background-color: $black-0;
            }
        }
    }

    .dragTrigger {
        position: absolute;
        width: 10px;
        top: 0;
        bottom: 0;
        right: 0;
        background: $black-400;
        cursor: col-resize;
        opacity: 0;
        @include transition(opacity);

        &:hover {
            opacity: 0.8;
        }
    }
}

.filtersPlaceholder {
    flex: 0 0 64px;
    flex-basis: 64px;
}

.filters {
    z-index: 2;
    flex: 0 0 64px;
    flex-basis: 64px;
    background-color: $black-0;
    position: relative;
    overflow: hidden;
    @include transition(flex-basis);

    &.withBorder {
        border-right: 1px solid $black-100;
    }

    .filtersListCollapsed,
    .filtersListExpanded {
        @include transition(opacity);
    }

    &.isResizing {
        transition: none;
    }

    .filtersListCollapsed {
        opacity: 1;
    }
    .filtersListExpanded {
        opacity: 0;
        pointer-events: none;
    }

    &.expanded {
        flex: 0 0 430px;
        flex-basis: 430px;

        .filtersListCollapsed {
            opacity: 0;
        }

        .filtersListExpanded {
            opacity: 1;
            pointer-events: all;
        }
    }

    &.isFullscreen {
        visibility: hidden;
    }

    @include small {
        position: fixed;
        left: 0;
        top: 64px;
        height: calc(100vh - 64px);
        height: calc(100dvh - 64px);

        box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);

        width: 64px;
        @include transition(width);

        &.expanded {
            width: 430px;

            @include mobile {
                width: 100vw;
            }
        }
    }
}

.content {
    flex: 1;
    display: flex;
    flex-direction: column;

    @include mobile {
        width: 100%;
    }
}

.expandButton {
    z-index: 100;
    width: 32px;
    height: 32px;
    background-color: $black-100;
    border-radius: 8px;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);

    @include transition(left, opacity);

    position: absolute;
    left: 0;
    margin-left: -16px;
    top: calc(50% - 16px);

    @include small {
        &.expanded {
            left: 430px !important;

            @include mobile {
                left: calc(100vw - 16px) !important;
            }
        }
    }

    &.isHidden {
        opacity: 0;

        @include small {
            opacity: 1;
        }
    }

    &:hover {
        background-color: $black-400;
    }

    cursor: pointer;

    .icon {
        width: 32px;
        height: 32px;
        background-image: url('./icons/expand.svg');
        background-repeat: no-repeat;
        background-position: center;
        transform-origin: 50% 50%;
    }
    &.expanded .icon {
        transform: rotate(180deg);
    }
}

.filters.isResizing + .expandButton {
    visibility: hidden;
    transition: none;
}