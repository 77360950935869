@import '../colors.scss';
@import '../mixins.scss';

.block {
    display: block;
}

.label {
    @include transition(width);
}

.input {
    font-family: 'Rubik', sans-serif;
    border: 2px solid $black-200;
    background-color: $black-0;
    border-radius: 8px;
    padding: 10px;
    color: $black-900;

    overflow: hidden;
    text-overflow: ellipsis;

    &.small {
        padding: 8px 16px;
    }

    &.search {
        height: 40px;
        padding-top: 0;
        padding-bottom: 0;
    }

    @include transition(border-color, color);

    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;

    &:hover {
        border-color: $black-400;
    }

    &:active,
    &.active {
        border-color: $primary;
    }

    &.error {
        border-color: $error;
    }

    &.disabled {
        border-color: $black-200;
        background-color: $black-100;
        color: $black-500;
    }
}

.rightIcon,
.leftIcon {
    width: 18px;
    height: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    flex-shrink: 0;

    &.clickable {
        cursor: pointer;
    }
}

select.inputField {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.inputField {
    flex: 1;
    border: none;
    outline: none;
    padding: 0;
    font-size: 16px;
    line-height: 24px;
    background-color: transparent;

    .small & {
        font-size: 14px;
        line-height: 14px;
    }

    &::placeholder {
        color: $black-600;
    }

    /** hide autofill */
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 30px $black-0 inset !important;
    }

    &:-webkit-autofill {
        -webkit-text-fill-color: $black-900 !important;
    }
}

.label {
    display: block;
    @include font(16px);
    font-weight: 500;
    margin-bottom: 4px;

    &.noGap {
        margin-bottom: 0px;
    }
}

.errorText {
    display: block;
    @include font();
    font-size: 14px;
    color: $error;

    margin-top: 0px;
    height: 0px;
    overflow: hidden;
    will-change: margin-top, height;
    @include transition(margin-top, height);

    &.visible {
        margin-top: 4px;
        height: 20px;
    }
    margin-bottom: 20px;

    .noGap & {
        margin-bottom: 0px;
    }
}