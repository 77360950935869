@import '../../../../../uikit/mixins.scss';
@import '../../../../../uikit/colors.scss';

.label {
    font-weight: 700;
    margin-bottom: 10px;
}

.namePreview {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 150px;
}

.certsList {
    max-height: 300px;
    width: 100%;
    overflow-y: auto;

    padding-top: 10px;

    @include scrollbars();

    .certItem {
        cursor: pointer;
        margin-bottom: 10px;
        background-color: $black-100;
        padding: 10px;

        @include transition(background-color);

        &:hover {
            background-color: $black-400;
        }

        &.active {
            background-color: $black-500;
        }

        .infoTitle {
            font-weight: 700;
        }

        .infoSubtitle {
            font-size: 13px;
            color: $black-800;
        }

        .infoItems {
            font-size: 13px;
            margin-top: 5px;
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: 5px;
        }

        .dateLabel {
            font-weight: 700;

            &.valid {
                color: #4caf50;
            }

            &.invalid {
                color: $error;
            }
        }
    }
}