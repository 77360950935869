@import '../../../uikit/mixins.scss';

.event {
    padding: 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.quickButtonsList {
    display: flex;
    gap: 6px;
    flex-flow: wrap;
}

.preview {
    padding: 10px;
    background: white;
    border-radius: 4px;
}

.eventsList {
    @include scrollbars();
    overflow-y: scroll;
    height: calc(100vh - 200px);
    height: calc(100dvh - 200px);
}