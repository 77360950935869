@import '../../uikit/mixins.scss';
@import '../../uikit/colors.scss';

.container {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .divider {
        height: 1px;
        background-color: $black-100;
    }

    .item {
        display: flex;
        flex-direction: row;
        gap: 6px;
        cursor: pointer;

        &:hover {
            .title .link {
                text-decoration: underline;
            }
        }

        .title {
            display: flex;
            align-items: start;
            flex-direction: column;
            gap: 2px;
            @include font(16px);
        }

        .meta {
            @include font(12px);
            color: $black-500;
        }
    }
}

.headerButton {
    cursor: pointer;
    margin-right: 10px;
    height: 30px;
    width: 30px;
    border-radius: 50%;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: transparent;

    @include fastTransition(background-color);

    &.active {
        background-color: #9382EA;
    }
}

.headerContainer {
    position: fixed;
    top: 40px;
    right: 200px;
    width: 400px;
    max-height: 400px;
    max-width: 100vw;
    padding: 24px 32px;
    border-radius: 12px;
    padding: 20px 16px;
    background: rgba(255, 255, 255, 0.8);
    border: 0.5px solid rgba(53, 53, 53, 0.2);
    border-radius: 20px;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(20px);
    z-index: 1000;

    @include scrollbars();

    @media (max-width: 600px) {
        right: 0;
    }

    &.hidden {
        display: none;
    }
}