@import '../../../uikit/mixins.scss';
@import '../../../uikit/colors.scss';

.row {
    display: flex;
    gap: 16px;
    align-items: center;
}

.downloadLink {
    background-image: url('../../../shared/icons/download.svg');
    width: 24px;
    height: 24px;
    cursor: pointer;
    display: inline-block;
}

.status {
    @include font(16px, 500);

    &.success {
        color: #69B245;
    }

    &.failed {
        color: #AF0007;
    }
}