@import '../../../uikit/mixins.scss';

.dashboard {
    @include scrollbars();
    height: 100%;
}

.grid {
    padding: 20px;
    display: grid;
    grid-gap: 15px;
    max-width: 1500px;
    margin: 0 auto;

    grid-template-areas: 
        "types rSales dataUpdate events"
        "gtinCount rPoints myTools events";

    @media screen and (max-width: 1300px) {
        grid-template-areas: 
            "types rSales events"
            "gtinCount rPoints events"
            "dataUpdate myTools events";
    }

    @media screen and (max-width: 1000px) {
        grid-template-areas: 
            "types rSales"
            "gtinCount rPoints"
            "dataUpdate events"
            "myTools events";
    }

    @media screen and (max-width: 650px) {
        grid-template-areas: 
            "types"
            "rSales"
            "gtinCount"
            "rPoints"
            "dataUpdate"
            "myTools"
            "events";
    }

    &.withQueue {
        grid-template-areas: 
        "types rSales dataUpdate events"
        "gtinCount rPoints myTools queue";

        @media screen and (max-width: 1300px) {
            grid-template-areas: 
                "types rSales events"
                "gtinCount rPoints queue"
                "dataUpdate myTools queue";
        }

        @media screen and (max-width: 1000px) {
            grid-template-areas: 
                "types rSales"
                "gtinCount rPoints"
                "dataUpdate queue"
                "myTools events";
        }

        @media screen and (max-width: 650px) {
            grid-template-areas: 
                "queue"
                "types"
                "rSales"
                "gtinCount"
                "rPoints"
                "dataUpdate"
                "myTools"
                "events";
        }
    }
}