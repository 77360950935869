@import '../../../../../../../uikit/mixins.scss';

.eventsWrapper {
    height: 100%;
    overflow: hidden;
    position: absolute;

    @include mobile {
        width: 100%;
    }
}

.events {
    @include scrollbars();
    height: 100%;

    display: flex;
    flex-direction: column;
    gap: 16px;
}

.loader {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    .noData {
        font-size: 16px;
        color: $black-700;
    }
}
