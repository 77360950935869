@import '../../../../../../uikit/colors.scss';
@import '../../../../../../uikit/mixins.scss';

.list {
    min-height: 100px;
    flex: 1 0 200px;
    @include scrollbars();

    display: flex;
    flex-direction: column;
    gap: 10px;
    position: relative;
}

.controlsLabel {
    @include mobile {
        display: none;
    }
}

.controls {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    padding: 10px 2px;
    border-bottom: 1px solid $black-100;
    margin-bottom: 12px;
    margin-top: 4px;
    gap: 16px;

    .control {
        @include font(13px, 400);
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        cursor: pointer;
        gap: 4px;

        opacity: 1;
        @include transition(opacity);

        &.disabled {
            cursor: not-allowed;
            opacity: 0.5;
        }

        position: relative;
    }
}

.fieldsMenu {
    position: absolute;
    margin-top: 5px;
    display: inline-flex;
    flex-direction: column;
    z-index: 600;
    padding: 15px;

    background: rgba(255, 255, 255, 0.5);
    border: 0.5px solid rgba(53, 53, 53, 0.2);

    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(20px);

    border-radius: 12px;
}

.loader, .nothingFound {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px;
    color: $black-600;
}

.datasets {
    margin-bottom: 10px;
}