@import '../uikit/mixins.scss';

:global {
    body {
        overflow: hidden;
    }

    div#react-turbo-select__menu {
        position: absolute;
        z-index: 10000000;
    }
}

.layout {
    display: flex;
    flex-direction: column;
    @include fullHeight();
    width: 100vw;
    overflow: hidden;
    background: rgba(229, 229, 229, 0.5);
}

.loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
}

.version {
    @include font(10px, 400);
    opacity: 0.3;
    position: fixed;
    bottom: 2px;
    right: 2px;
    z-index: 1000;
}