@import '../../../../../../uikit/mixins.scss';

.rightClickHint {
    display: flex;
    align-items: center;
    gap: 3px;
    cursor: default;

    .rightClickHintLabel {
        opacity: 0.7;
        white-space: nowrap;
        @include font(14px, 400, 14px);
    }

    .rightClickHintIcon {
        opacity: 0.85;
    }
}