@import '../../../uikit/mixins.scss';
@import '../../../uikit/colors.scss';

.row {
    display: flex;
    gap: 16px;
    align-items: center;
}

.extraFileGroup {
    margin-left: 31px;
}

.attention {
    width: 100%;
    max-width: 514px;

    padding: 12px;
    padding-right: 54px;

    @include font(14, 400);

    background: rgba(254, 136, 136, 0.2);
    border-radius: 12px;

    margin-bottom: 12px;
}

.uploadModal {
    max-width: 514px;

    .fileName {
        word-break: break-all;
    }
}

.fileValidationList {
    line-height: 233%;
    margin-bottom: 2px;

    .error {
        color: #FE8888;
    }
}

.divider {
    background: #DADADA;
    height: 1px;
}