@import '../../../../../uikit/colors.scss';
@import '../../../../../uikit/mixins.scss';

.widget {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    @include mobile {
        position: relative;
    }

    background: $black-0;
    border-radius: 20px;

    padding: 24px 32px;

    display: flex;
    flex-direction: column;

    .title {
        @include font(20px, 700, 32px);
    }

    .subTitle {
        @include font(12px, 400, 16px);
        color: $black-700;
        margin-top: 4px;
    }

    .component {
        margin-top: 16px;
        flex: 1 1 auto;

        position: relative;
    }
}

.wrapper {
    position: relative;
    min-height: 288px;
}