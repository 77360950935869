@import '../../../../uikit/mixins.scss';

.button {
    cursor: pointer;
    padding: 6px;
    margin-right: -12px;

    .icon {
        svg {
            height: 27px !important;
            width: 27px !important;
        }
    }

    position: relative;

    .unread {
        position: absolute;
        top: 6px;
        right: 17px;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: #FE8888;
        opacity: 0;
        @include transition(opacity);

        &.visible {
            opacity: 1;
        }
    }

    .notifications {
        position: absolute;
        top: 40px;
        right: 0;
        width: 344px;
        height: 250px;
        z-index: 1000;
        background: rgba(255, 255, 255, 0.8);
        border: 0.5px solid rgba(53, 53, 53, 0.2);
        box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(20px);
        border-radius: 12px;
        padding: 20px 16px;

        .eventsWrapper {
            width: calc(100% - 32px);
            height: calc(100% - 40px);
        }

        @include mobile {
            position: fixed;
            width: 100%;
            left: 0;
            top: 64px;
        }
    }
}