@import '../../../../../uikit/colors.scss';
@import '../../../../../uikit/mixins.scss';

.pagination {
    background: $black-0;
    border-radius: 30px;
    height: 40px;
    padding: 2px 4px;
    overflow: hidden;
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    flex-shrink: 0;
    gap: 6px;

    .page {
        position: relative;
        flex-shrink: 0;
        flex-grow: 0;
        border: none;
        background-color: transparent;
        cursor: pointer;
        padding: 7px;
        min-width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        @include font(16px, 400, 16px);

        border-radius: 30px;

        @include middle {
            min-width: 24px;
            height: 24px;
        }

        &.withArrow {
            width: 30px;

            @include middle {
                width: 24px;
            }
        }

        @include transition(background-color);

        &:hover {
            background-color: $black-100;
        }

        &.active {
            background-color: $primary;
        }

        &[disabled] {
            opacity: 0.5;
            pointer-events: none;
        }

        .arrow {
            height: 100%;
            width: 100%;

            &.reverse {
                transform: rotate(180deg);
            }

            &.compact {
                margin: 0 -40%;
            }
        }
    }
}