@import '../../../uikit/colors.scss';
@import '../../../uikit/mixins.scss';

$padding: 20px;
$margin: 16px;

.chart {
    background-color: $black-0;
    padding: $padding;
    margin: 0 $margin $margin $margin;
    border-radius: 12px;
    width: calc(100% - 2 * $margin);
    max-height: calc(100% - $margin);
    position: absolute;

    @include scrollbars();

    &.fullHeight {
        height: calc(100% - $margin);
    }

    &.withLegend {
        width: calc(100% - 2 * $margin - 466px);

        @include mobile {
            position: relative;
            width: calc(100% - 32px);
            height: 50%;
        }
    }

    :global .fullscreen :local &,
    :global .js-screenshot-processing :local & {
        margin: $margin;
        max-height: calc(100% - 2 * $margin);
        
        &.fullHeight {
            height: calc(100% - 2 * $margin);
        }
    }

    .container {
        position: relative;
        width: 100%;
        height: 100%;
        overflow: visible;
    }
}