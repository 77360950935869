@import '../../../../../uikit/colors.scss';
@import '../../../../../uikit/mixins.scss';

.button {
    width: 40px;
    height: 40px;

    background-color: $black-0;
    border-radius: 20px;

    display: flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;

    &:hover {
        background-color: $black-200;
    }

    @include middle {
        order: -1;
    }
}