@import '../../../../../../uikit/colors.scss';
@import '../../../../../../uikit/mixins.scss';

.meta {
    @include font(14px, 400);
    color: $black-700;
    margin-bottom: 19px;
    padding-bottom: 10px;
    border-bottom: 1px solid $black-100;
}   

.selectType {
    padding-bottom: 10px;
    border-bottom: 1px solid $black-100;
    margin-bottom: 16px;
}

.yearSelect {
    margin-bottom: 19px;
}

.list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 12px;

    .item {
        background: $black-0;
        border: 1px solid $black-100;
        border-radius: 2px;
        padding: 4px 22px;
        @include font(13px, 400);

        &.selected {
            background: $primary;
        }
    }
}

.container {
    :global {
        .react-datepicker {
            @include font(14px, 400);
            border: none;

            &__current-month {
                @include font(14px, 400);
                margin-bottom: 10px;
            }

            &__week-number {
                @include font(14px, 500);
            }

            &__day-name {
                @include font(14px, 500);
                margin: 3px 7px;
            }

            &__day {
                padding: 3px 9px;
                box-sizing: content-box;
                background-color: $black-0;
                color: $black-900;
                width: 24px;
                height: 24px;
                border-radius: 2px;
                display: inline-flex;
                align-items: center;
                justify-content: center;

                &--in-range,
                &--in-selecting-range {
                    background-color: $black-100;
                }
                
                &--selected,
                &--range-start,
                &--range-end {
                    background: $primary;
                }

                &--disabled {
                    opacity: 0.3;
                }
            }

            &__header {
                background-color: $black-0;
                border-bottom: none;
                padding: 0;
            }
        }
    }
}

.availablePeriodEdge {
    position: relative;

    &::after {
        content: '';
        position: absolute;
        top: 4px;
        right: 4px;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background: $primary;
    }
}

.notAvailable {
    opacity: 0.3;
    pointer-events: none;
}