@import '../mixins';
@import '../colors.scss';

.button {
    padding: 12px 24px;
    border-radius: 1000px;

    @include font(16px);
    text-decoration: none;
    &:hover {
        color: $black-900;
    }

    outline: none;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;

    white-space: nowrap;

    @include transition(background-color);

    @include middle {
        padding: 4px 12px;
        height: 32px;
    }
}

.fullWidth {
    width: 100%;
    flex-grow: 1;
}

.flex {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.small {
    padding: 6px 16px;
    @include font(14px);
}

.iconOnly {
    width: 40px;
    height: 40px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.reportPanel {
    height: 40px;
    padding-top: 0;
    padding-bottom: 0;
}

.white {
    background-color: $black-0;

    &:hover {
        background-color: $black-100;
    }

    &:active {
        background-color: $black-200;
    }
}

.grey {
    background-color: $black-100;

    &:hover {
        background-color: $black-200;
    }

    &:active {
        background-color: $black-300;
    }
}

.darkGrey {
    background-color: $black-200;

    &:hover {
        background-color: $black-300;
    }

    &:active {
        background-color: $black-400;
    }
}

.black {
    background-color: $black-900;
    color: $black-0;

    &:hover {
        background-color: $black-800;
        color: $black-0;
    }

    &:active {
        background-color: $black-700;
        color: $black-0;
    }
}

.yellow {
    background: $primaryGradient;

    &:hover {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05)), $primaryGradient;
    }

    &:active {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), $primaryGradient;
    }
}

.bordered {
    border: 2px solid $black-900;
    padding: 10px 62px;
}

.button {
    &.disabled,
    &[disabled] {
        background-color: $black-100;
        background: $black-100;
        color: $black-600;
        cursor: not-allowed !important;
    }
}