@import '../../../uikit/colors.scss';
@import '../../../uikit/mixins.scss';

.event {
    display: flex;
    flex-direction: column;
    padding-left: 12px;

    border-left: 3px solid transparent;

    text-decoration: none;

    &.error {
        border-left-color: #FE8888;
    }

    &.info {
        border-left-color: #ABD490;
    }

    &.other {
        border-left-color: #AAAAAA;
    }

    .title {
        margin-bottom: 4px;
        @include font(14px, 700);

        &.withLink {
            text-decoration: underline;

            &:hover {
                color: $black-800;
            }
        }
    }

    .text {
        margin-bottom: 8px;
        @include font(12px, 400);
    }

    .date {
        @include font(12px, 400);
        color: $black-600;

        display: flex;
        align-items: center;
        gap: 8px;
    }

    &.viewed {
        border-left-color: #aaaaaa3e;
    }
}