@import '../../uikit/colors.scss';
@import '../../uikit/mixins.scss';

.disclaimers {
    background-color: $black-0;
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    gap: 10px;

    .disclaimer {
        width: 800px;
        max-width: 100%;

        padding: 10px;
        @include font(14, 400);

        border-radius: 10px;

        border-left: 5px solid #ff5722;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        background: $black-100;
        
        &.error {
            border-left-color: #ff5722;
        }

        &.warning {
            border-left-color: $primary;
        }

        &.information {
            border-left-color: #4caf50;
        }
    }
}