.container {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    height: 100%;
    padding: 20px;

    .left {
        flex: 0 0 500px;
        position: relative;
    }

    .right {
        flex: 1 1 auto;
        display: flex;
    }
}

.modal {
    min-height: 400px;
    min-width: 700px;
    position: relative;
}

.formatTable {
    height: 260px;
    position: relative;
}