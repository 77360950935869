@import '../../../../uikit/mixins.scss';
@import '../../../../uikit/colors.scss';

.wrapper {
    z-index: 700;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    flex: 0 0 auto;

    pointer-events: none;
    &.visible {
        pointer-events: auto;
    }
}

.modal {
    position: fixed;
    @include fullHeight();
    @include scrollbars();
    width: 477px;
    max-width: 100%;
    right: 0;
    @include transition(transform);
    will-change: transform;
    background: $black-0;

    transform: translateX(100%);

    padding: 64px 40px;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);

    @include mobile {
        padding: 32px 16px;
    }

    &.visible {
        transform: translateX(0);
    }

    .close {
        background-image: url('./close.svg');
        width: 40px;
        height: 40px;
        background-size: 40px;
        background-repeat: no-repeat;

        position: absolute;
        top: 64px;
        right: 40px;
        cursor: pointer;


        @include mobile {
            top: 40px;
            right: 16px;
        }
    }

    .title {
        @include font(32px, 700);
        margin-bottom: 24px;
    }

    .subTitle {
        @include font(20px, 500);
        margin-bottom: 20px;
        margin-top: 36px;

        display: flex;
        align-items: center;
        gap: 6px;
    }

    .profile {
        .name {
            @include font(20px, 600);
        }

        .meta {
            @include font(16px, 400);
            color: $black-600;
        }

        margin-bottom: 16px;
    }

    .email,
    .inn {
        .label {
            @include font(16px, 500);
            display: flex;
            align-items: start;
            gap: 4px;
        }
        .value {
            @include font(16px, 400);
            opacity: 0.5;
        }

        margin-bottom: 16px;
    }

    .inn {
        margin-top: 24px;
    }
}

.status {
    @include font(16px, 400);

    color: #FE8888;

    &.active {
        color: #ABD490;
    }
}

.spacer {
    flex: 1 1 auto;
}

.toggleStatus {
    @include font(16px, 400);
    cursor: pointer;
    margin-left: 10px;
    text-decoration: underline;
    display: inline-block;
}