@import '../../../../../uikit/mixins.scss';

.periodsList {
    margin-top: 16px;
    margin-bottom: 20px;

    max-height: 300px;
    @include scrollbars();

    display: flex;
    flex-direction: column;
    gap: 10px;
}