@import '../../../uikit/colors.scss';
@import '../../../uikit/mixins.scss';

.menu {
    z-index: 100;
    background: $black-0;
    border: 0.5px solid rgba(53, 53, 53, 0.2);
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    min-width: 128px;
    overflow: hidden;

    .item {
        @include font(16px, 400);
        padding: 12px;
        cursor: pointer;

        &:hover {
            background-color: $black-100;
        }

        &.disabled, &.disabled:hover {
            color: $black-300;
            cursor: not-allowed;
        }
    }

    .divider {
        height: 1px;
        background: #ebebf5;
    }
}
