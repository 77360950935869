@import '../../../../../uikit/colors.scss';
@import '../../../../../uikit/mixins.scss';

.withIcon {
    display: flex;
    align-items: center;
    flex: 0 0 auto;
    padding: 0;
    width: 40px;
    height: 40px;
    border-radius: 20px;
}