@import '../../../uikit/colors.scss';
@import '../../../uikit/mixins.scss';

$padding: 20px;
$margin: 16px;

.legend {
    background-color: $black-0;
    padding: $padding;
    margin: 0 $margin $margin $margin;
    height: calc(100% - $margin);

    :global .fullscreen :local &,
    :global .js-screenshot-processing :local & {
        margin: $margin;
        max-height: calc(100% - 2 * $margin);
    }

    border-radius: 12px;
    position: absolute;

    @include scrollbars();

    width: 450px;
    right: 0;

    @include mobile {
        width: calc(100% - 32px);
        height: 50%;
        position: relative;
    }

    .title {
        @include font(20px, 500);
        margin-bottom: 24px;
    }

    .item {
        margin-bottom: 10px;
        border-radius: 4px;
        min-height: 24px;
        display: flex;
        align-items: center;
    }
}