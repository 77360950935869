@import '../../../uikit/colors.scss';
@import '../../../uikit/mixins.scss';

.supportPageContainer {
    img {
        max-width: 462px !important;
        width: auto !important;
        margin-bottom: 16px !important;
        display: block !important;
        border: 12px solid #F2F2F2 !important;
        border-radius: 16px !important;
    }

    h1 {
        margin-top: 40px !important;
        @include font(24px, 700, 32px);
        margin-bottom: 16px !important;
        position: relative !important;

        &:first-child {
            margin-top: 0 !important;
        }

        &::before {
            content: '' !important;
            position: absolute !important;
            width: 4px !important;
            height: 24px !important;
            background: $primary !important;
            left: -12px !important;
            top: 50% !important;
            transform: translateY(-50%) !important;
            display: flex !important;
            align-items: center !important;
            justify-content: center !important;
            @include font(16px, 700, 32px);
            color: #fff !important;
        }
    }

    h2 {
        margin-top: 40px !important;
        @include font(18px, 700, 24px);
        margin-bottom: 24px !important;
        position: relative !important;

        &:first-child {
            margin-top: 0 !important;
        }

        &::before {
            content: '' !important;
            position: absolute !important;
            width: 4px !important;
            height: 18px !important;
            background: $primary !important;
            left: -12px !important;
            top: 50% !important;
            transform: translateY(-50%) !important;
            display: flex !important;
            align-items: center !important;
            justify-content: center !important;
            @include font(16px, 700, 32px);
            color: #fff !important;
        }
    }

    h3, h4, h5, h6, p, li, blockquote {
        @include font(16px, 400, 24px);
    }

    hr {
        border: none !important;
        border-top: 1px solid #E5E5E5 !important;
        margin: 40px 0 !important;
    }

    blockquote {
        background: rgba(242, 242, 242, 0.6) !important;
        padding: 16px 32px !important;
        border-left: none !important;
        margin-bottom: 0 !important;

        & + blockquote {
            margin-top: 0 !important;
        }

        &:first-of-type {
            padding-top: 24px !important;
        }

        &:last-of-type {
            padding-bottom: 24px !important;
        }
    }

    a {
        color: #316BFF !important;
    }

    p {
        margin-bottom: 16px !important;
        margin-top: 16px !important;

        &:first-child {
            margin-top: 0 !important;
        }

        &:last-child {
            margin-bottom: 0 !important;
        }
    }

    td > p {
        margin-bottom: 0 !important;
    }

    td {
        padding: 8px !important;
        border: 1px solid #E5E5E5 !important;
    }

    tr:first-child td p {
        font-weight: 700 !important;
    }

    :global {
        li.ql-indent-1 {
            margin-left: 1.5em !important;
        }

        li.ql-indent-2 {
            margin-left: 3em !important;
        }

        li.ql-indent-3 {
            margin-left: 4.5em !important;
        }
    }
}