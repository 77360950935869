@import '../../../../../uikit/colors.scss';
@import '../../../../../uikit/mixins.scss';

.switcher {
    height: 40px;
    background: #FFFFFF;
    border-radius: 10000px;
    gap: 4px;
    display: flex;
    align-items: center;
    padding: 8px 12px;

    .value {
        @include font(16px, 400);
        display: inline-block;
        width: 200px;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        @include mobile {
            width: 100px;
        }
    }

    .button {
        width: 24px;
        height: 24px;
        background-image: url('./arrow.svg');
        background-repeat: no-repeat;
        background-position: center;

        cursor: pointer;

        &.right {
            transform: rotate(180deg);
        }
    }
}