.container {
    padding: 16px;
    position: absolute;
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;

    overflow: hidden;

    .inputs {
        width: 100%;
        display: flex;
        flex-direction: row;
        margin-bottom: 16px;
        gap: 5px;
        flex-wrap: wrap;
    }

    .hint {
        opacity: 0.6;
    }

    .editor {
        flex: 1;
        position: relative;

        .quillContainer,
        .textarea,
        .preview {
            position: absolute;
            width: 100%;
            height: 100%;
        }

        .preview {
            background: white;
            padding: 16px;
            overflow-y: scroll;
        }

        .quill {
            height: 100%;
            background-color: white;

            :global {
                .ql-toolbar {
                    position: absolute;
                    top: 0;
                    width: 100%;
                    left: 0;
                    z-index: 9;
                }

                .ql-container {
                    padding-top: 50px;
                }

                .ql-picker.ql-table .ql-picker-label::before {
                    content: "T" !important;
                }
            }
        }
    }
}