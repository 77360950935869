@import '../../../uikit/colors.scss';
@import '../../../uikit/mixins.scss';

.page {
    background: $pageBackground;
    display: flex;
    flex-direction: row;
    gap: 28px;

    @include fullHeight();
    padding: 32px 64px;
    align-items: stretch;

    @media screen and (max-width: 1150px) {
        flex-direction: column;
        padding: 16px 32px;
    }

    @media screen and (max-width: 750px) {
        flex-direction: column;
        padding: 0;
    }
}

.leftSide,
.rightSide {
    @include border-radius();
    overflow: hidden;
}

.leftSide {
    flex: 0 0 576px;
    background: $primaryGradient;
    position: relative;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media screen and (max-width: 1300px) {
        flex-shrink: 2;
    }

    @media screen and (max-width: 1150px) {
        flex-basis: auto;
    }

    @media screen and (max-width: 750px) {
        display: none;
    }

    @media screen and (max-width: 1150px) and (max-height: 850px) {
        display: none;
    }
}

.rightSide {
    flex-grow: 1;
    flex-shrink: 1;
    background: $black-0;

    display: flex;
    justify-content: center;
    align-items: center;

    padding: 60px;

    @include small {
        padding: 30px;
    }

    @include mobile {
        padding: 16px;
    }

    @media screen and (max-width: 750px) {
        border-radius: 0;
    }
}

.modal {
    .modalTitle {
        @include font(22px, 700);
        margin-bottom: 4px;
    }

    .text {
        @include font(16px, 400);
        margin-bottom: 16px;
    }

    .modalButtons {
        display: flex;
        justify-content: stretch;
        align-items: center;
        gap: 16px;
    }
}

.form {
    width: 599px;
    max-width: 100%;
}

.formTitle {
    @include font(32px, 500);
    margin-bottom: 4px;
}

.formSubtitle {
    @include font(20px, 400);
    color: $black-600;
    margin-bottom: 24px;
}

.logo {
    width: 102px;
    height: 32px;
    flex: 0 0 32px;

    background-image: url('../../../shared/images/ridaLogo.svg');
    background-size: cover;

    margin-left: 60px;
    margin-top: 60px;
}

.title {
    padding: 60px;
    @include font(44px, 900, 54px);
}

.forgot {
    margin-bottom: 58px;
    text-align: right;
    @include font();

    .forgotText {
        cursor: pointer;
    }
}

.image {
    width: 80%;
    height: 36%;
    background-image: url('./image.png');
    background-size: cover;
    align-self: flex-end;
    justify-self: flex-end;

    @media screen and (max-width: 1150px) {
        display: none;
    }
}