.headerItems {
    flex-grow: 1;
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 16px;
}

.hiddenInput {
    display: none;
}

.hint {
    opacity: 0.5;
    font-size: 90%;
    padding: 0 16px;
}