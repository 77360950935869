@import '../../../../../../../uikit/colors.scss';
@import '../../../../../../../uikit/mixins.scss';

.loader {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    .noData {
        font-size: 16px;
        color: $black-700;
    }
}

.chart {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

.tooltip {
    border-radius: 6px;
}