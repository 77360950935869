@keyframes randomShake {
    0% {
        transform: translate(0, 0) rotate(0);
    }
    10% {
        transform: translate(-1px, 0) rotate(-1deg);
    }
    20% {
        transform: translate(1px, 0) rotate(1deg);
    }
    30% {
        transform: translate(-1px, 0) rotate(-1deg);
    }
    40% {
        transform: translate(1px, 0) rotate(1deg);
    }
    50% {
        transform: translate(-1px, 0) rotate(-1deg);
    }
    60% {
        transform: translate(1px, 0) rotate(1deg);
    }
    70% {
        transform: translate(-1px, 0) rotate(-1deg);
    }
    80% {
        transform: translate(1px, 0) rotate(1deg);
    }
    90% {
        transform: translate(-1px, 0) rotate(-1deg);
    }
    100% {
        transform: translate(0, 0) rotate(0);
    }
}

.forceProd {
    position: fixed;
    bottom: 23px;
    right: 4px;
    z-index: 150;
    opacity: 0.5;
    &:hover {
        opacity: 1;
        animation: randomShake 0.5s infinite;
    }
}

.memoryStats {
    background: white;
    padding: 8px;
    border-radius: 8px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    position: fixed;
    bottom: 50px;
    right: 4px;
    z-index: 150;
    opacity: 0.8;
    font-size: 12px;
    &:hover {
        opacity: 1;
    }
}