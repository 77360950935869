@import '../../../../../../../uikit/mixins.scss';

.queueWrapper {
    height: 100%;
    width: 100%;
    overflow: hidden;
    position: absolute;

    @include mobile {
        width: 100%;
    }
}

.queue {
    @include scrollbars();
    height: 100%;

    display: flex;
    flex-direction: column;
    gap: 16px;
}