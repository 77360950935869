@import '../../../uikit/colors.scss';
@import '../../../uikit/mixins.scss';

.select {
    background-color: $black-0;
    border-radius: 100px;
    padding: 7px 12px;
    @include font(16px, 400);
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 4px;
    @include transition(background-color);
    &:hover {
        background-color: $black-200;
    }

    &.small {
        height: 40px;
        padding: 7px;
    }

    position: relative;

    white-space: nowrap;

    .expand {
        width: 24px;
        height: 24px;
        background-image: url('../../../shared/icons/expandFilter.svg');
        transform-origin: center;
        transform: rotate(180deg);
        &.expanded {
            transform: rotate(0);
        }
    }
}

.options {
    position: absolute;
    z-index: 10;
    width: 200px;

    max-height: 400px;
    overflow: auto;
    @include scrollbars;

    background-color: $black-0;
    border-radius: 8px;
    border: 0.5px solid rgba(53, 53, 53, 0.2);
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);

    visibility: hidden;
    opacity: 0;
    pointer-events: none;

    &.expanded {
        visibility: visible;
        opacity: 1;
        pointer-events: all;
    }

    .option {
        padding: 12px 24px;
        border-left: 4px solid transparent;
        cursor: pointer;

        &:hover {
            background-color: $black-100;
        }

        &.active {
            border-left: 4px solid $primary;
        }

        &.disabled {
            cursor: not-allowed;
            opacity: 0.5;
        }
    }
}