@import '../../../uikit/colors.scss';
@import '../../../uikit/mixins.scss';

.headerRow {
    display: flex;
    gap: 24px;
    align-items: center;
}

.notFound {
    font-size: 24px;
    padding: 20px;
    text-align: center;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    gap: 16px;
    height: calc(100% - 72px);
    display: flex;
    color: $black-600;
}

.filtersRow {
    position: absolute;
    z-index: 1;
    background: #f2f2f2;
    top: 0;
    width: 100%;
    padding: 16px 40px 16px 40px;

    @include transition(box-shadow);

    pointer-events: none;

    .filtersRowItem {
        pointer-events: all;
    }

    @include small {
        padding: 16px;
    }

    display: flex;
    gap: 24px;
    justify-content: space-between;
}

.reports {
    padding: 8px 40px 16px 40px;

    @include small {
        padding: 16px;
    }

    display: flex;
    gap: 24px;
    flex-wrap: wrap;

    position: absolute;
    padding-top: 72px;
    height: 100%;
    overflow: auto;
    align-content: flex-start;
    justify-content: center;
    @include scrollbars();
    overflow-y: scroll;

    @include large {
        &.table {
            flex-direction: column;
            align-items: stretch;
            flex-wrap: nowrap;
            justify-content: flex-start;
            width: 100%;
            gap: 0;

            .report {
                width: 100%;
                flex: 0 0 auto;
                border-bottom: $black-200 1px solid;
                border-radius: 0;
                padding: 10px;

                &:first-child {
                    border-top-left-radius: 16px;
                    border-top-right-radius: 16px;
                }

                &:last-child {
                    border-bottom: none;
                    border-bottom-left-radius: 16px;
                    border-bottom-right-radius: 16px;
                }

                .title {
                    order: 0;
                }
                .tags {
                    order: 1;
                }

                .titleGroup {
                    width: 417px;
                    flex: 0 0 417px;
                    margin-bottom: 0;
                }

                .description {
                    margin-right: 80px;
                    max-width: 500px;
                }

                .key {
                    margin-right: 30px;
                }

                .right {
                    flex-direction: row;
                    align-items: center;
                }
                
                .moduleBHint {
                    margin-left: 10px;
                    margin-top: 0;
                }
            }
        }
    }
}

.report {
    width: 435px;
    flex: 0 0 435px;

    background-color: $black-0;
    border-radius: 16px;
    padding: 24px;

    display: flex;
    gap: 24px;

    position: relative;
    overflow: hidden;

    .favorite {
        position: absolute;
        right: 12px;
        top: -6px;
        transform-origin: right top;
        transform: scale(1.05);
    }

    @include middle {
        width: 100%;
        flex: 0 0 auto;
    }

    @include small {
        padding: 16px;
        gap: 10px;
    }

    .buttonsRow {
        display: flex;
        gap: 12px;
        align-items: center;
    }

    .left {
        width: 67px;
        flex: 0 0 67px;

        .icon {
            background: rgba(240, 240, 240, 0.5);
            border-radius: 8px;

            width: 67px;
            height: 67px;
            margin-bottom: 8px;

            display: flex;
            justify-content: center;
            align-items: center;
        }

        .key {
            @include font(12px, 400);
            white-space: nowrap;
            color: #333333;
        }
    }

    .right {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .tags {
            display: flex;
            gap: 8px;

            .tag {
                @include font(12px, 400);
                color: $black-600;
            }
        }

        .title {
            @include font(20px, 700, 24px);
            color: #333333;

            @include small {
                @include font(18px, 700, 20px);
            }
        }

        .titleGroup {
            display: flex;
            gap: 3px;
            flex-direction: column;
            margin-bottom: 16px;
        }

        .key {
            @include font(12px, 400);
            white-space: nowrap;
            color: #333333;
        }

        .description {
            @include font(14px, 400);
            color: #333333;
            margin-bottom: 11px;
            flex-grow: 1;
        }
    }

    .moduleBHint {
        margin-top: 10px;
        @include font(12px, 400);
        color: $black-600;
    }
}

.tagsSelect {
    display: flex;
    background-color: $black-100;
    border-radius: 50px;
    gap: 2px;
    height: 40px;
    padding: 2px;

    @include middle {
        flex-wrap: wrap;
    }

    .closeIcon {
        position: relative;
        
        transform-origin: center;

        transform: rotate(90deg);
    }

    .tag {
        &.hidden {
            display: none;
        }

        &.close {
            padding-left: 9px;
            padding-right: 9px;
        }

        border: none;
        padding: 0 10px;
        height: 36px;
        @include font(14px, 400, 14px);
        position: relative;
        border-radius: 50px;

        display: flex;
        align-items: center;

        white-space: nowrap;

        .close {
            display: none;
        }

        &.selected {
            background-color: $black-900;
            color: $black-0;
            padding-right: 36px;

            .close {
                display: inline-block;
                background-image: url('../../../shared/icons/close.svg');
                width: 20px;
                height: 20px;
                position: absolute;
                right: 12px;
                top: 7px;
            }
        }
    }
}