@import '../../uikit/mixins.scss';
@import '../../uikit/colors.scss';

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(100vh - 65px);
  height: calc(100dvh - 65px);
  flex-direction: column;
  gap: 20px;
  padding: 16px;
}

.container {
  background: #FFFFFF;
  border-radius: 20px;
  padding: 24px 32px;
}

.title {
  margin-bottom: 16px;
  @include font(20px, 700);
}

.statuses {
  text-align: left;
  display: flex;
  flex-direction: column;
  gap: 8px;

  .line {
    display: flex;
    flex-direction: row;
    gap: 6px;
    align-items: center;
  }
}