@import '../../../uikit/colors.scss';
@import '../../../uikit/mixins.scss';

.header {
    padding: 20px;
    &.withGap {
        margin-bottom: 20px;
    }
    background-color: $black-0;
    &.noBackground {
        background-color: transparent;
    }
    position: relative;
    flex: 0 0 auto;

    @include mobile {
        padding: 16px;
    }

    min-height: 80px;

    &.isFullscreen {
        visibility: hidden;
    }

    .titleLine {
        min-height: 28px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        @include mobile {
            flex-direction: column;
            align-items: flex-start;
            gap: 8px;
        }
    }

    .title {
        position: absolute;
        top: 26px;
        left: 20px;
        
        span {
            @include font(28px, 700, 28px);
        }
        display: flex;
        align-items: center;
        flex-direction: row;
        gap: 8px;

        @include small {
            span {
                @include font(20px, 700, 20px);
            }
        }

        &.editable {
            cursor: text;

            &:focus {
                outline: none;
            }
        }

        &.withOptions {
            cursor: pointer;
        }

        &.optionsVisible {
            opacity: 0.8;
        }
    }
}

.expandIcon {
    @include mobile {
        display: none;
    }
}

.optionsList {
    @include fadeInFromTopAnimation(0.15s);
    
    z-index: 1000;
    background: rgba(255, 255, 255, 0.5);
    border: 0.5px solid rgba(53, 53, 53, 0.2);
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(20px);
    border-radius: 12px;
    padding: 20px 16px;

    display: flex;
    flex-direction: column;
    gap: 8px;
    position: absolute;
    transform-origin: left top;
    left: 0;
    top: 0;
    
    .option {
        @include font(16px, 400);
        text-decoration: none;
        &:hover {
            text-decoration: none;
        }
        &.active {
            font-weight: 600;
        }

        display: flex;
        align-items: center;
        gap: 8px;

        .hint {
            opacity: 0.3;
            @include font(14px, 400);
        }
    }

    @include mobile {
        max-width: calc(100vw - 100px);
        max-height: 60vh;
        overflow: auto;

        .hint {
            display: none;
        }
    }
}

.content {
    display: flex;
    flex-direction: row;
    justify-content: right;
    align-items: center;

    @include middle {
        align-items: start;
        flex-wrap: wrap;
        justify-content: start;
    }

    &.alignRight {
        justify-content: right;

        @include mobile {
            justify-content: start;
        }
    }

    &.alignLeft {
        justify-content: left;

        @include mobile {
            justify-content: start;
        }
    }

    margin-top: 16px;
    gap: 16px;
}

.back {
    display: flex;
    align-items: center;
    gap: 0;
    cursor: pointer;

    .backText {
        @include font(16px, 400, 16px);
    }

    padding-right: 6px;
    border-right: 1px solid rgba(53, 53, 53, 0.2);

    .backIcon {
        transform-origin: center;
        transform: rotate(-90deg);
    }
}