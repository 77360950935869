@import '../../../uikit/colors.scss';
@import '../../../uikit/mixins.scss';

.wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    transform-origin: left top;
}

.table {
    background-color: $black-0;
    border-radius: 12px;
    margin: 0 16px 16px 16px;
    max-height: calc(100% - 32px);
    width: calc(100% - 32px);
    overflow: auto;

    :global .fullscreen :local &,
    :global .js-screenshot-processing :local & {
        margin: 16px;
    }

    &.noMargin {
        margin: 0;
        width: 100%;
    }

    @include scrollbars();

    display: grid;
    grid-template-rows: 58px;
    grid-auto-rows: min-content;

    .head,
    .body,
    .row {
        display: contents;
    }

    .fullRow {
        grid-column: 1 / -1;
        padding: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .head .cell {
        position: sticky;
        top: 0;
        background-color: $black-0;

        @include font(16px, 500);

        border-bottom: 1px solid $black-100;
        
        &.sortable {
            cursor: pointer;
        }

        display: flex;
        align-items: center;

        &.numeric {
            justify-content: flex-end;
        }

        .sort {
            background-image: url('./sort.svg');
            width: 20px;
            height: 28px;
            margin-left: 8px;

            &.asc {
                background-image: url('./sortAsc.svg');
            }

            &.desc {
                background-image: url('./sortDesc.svg');
            }
        }

        &.fixed {
            z-index: 11;
        }
    }

    .body .cell {
        @include font(16px, 400);

        display: flex;
        align-items: center;
        justify-content: space-between;

        gap: 10px;

        .value {
            flex: 0 0 auto;

            &.grow {
                flex: 1 0 auto;
            }
        }

        .spacer {
            flex: 1 1 auto;
        }

        .bar {
            flex: 0 0 auto;
            background: #9382EA;
            height: 6px;
            border-radius: 6px;
            margin: 0 8px;
            width: 0;
            transition: width .15s ease-in-out;
        }
    }

    .row:nth-child(2n-1) .cell {
        background-color: $black-0;
    }
    .row:nth-child(2n) .cell {
        background-color: #F9F9F9;
    }
    .row:hover .cell {
        background-color: $black-100;
    }

    .cell {
        padding: 16px 16px;
        white-space: nowrap;

        &.fixed {
            position: sticky;
            left: 0;
            z-index: 10;

            &.last {
                border-right: 2px solid $black-100;
            }
        }

        &.numeric {
            text-align: right;
        }

        &.clickable {
            cursor: pointer;
        }

        &.withSize,
        &.withSize .value {
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;

            &.overflowVisible {
                overflow: visible;
            }
        }

        &.withBar .value {
            width: auto;
        }

        &.showOnHover .value {
            visibility: hidden;

            @include small {
                visibility: visible;
            }
        }
    }

    .row:hover .cell.showOnHover .value {
        visibility: visible;
    }
}