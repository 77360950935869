@import '../../../uikit/colors.scss';
@import '../../../uikit/mixins.scss';

.regionsMap {
    background-color: $black-0;
    display: grid;
    overflow: visible;
    z-index: 200;

    &.compact .region {
        gap: 1px;
    }

    .legend {
        grid-area: 8/12/10/18;

        border: 1px dashed #BEBDBD;
        padding: 29px 32px;
        border-radius: 10px;

        display: flex;
        align-items: center;
        gap: 10px;

        .region {
            flex: 0 0 auto;
            transform: scale(1.25);

            &:hover {
                transform: scale(1.25);
            }
        }

        .settings {
            display: flex;
            flex-direction: column;
            gap: 10px;

            .line {
                display: flex;
                align-items: center;

                .arrow {
                    width: 25px;
                    height: 7px;
                    background-image: url('./legendArrow.svg');
                    background-repeat: no-repeat;
                    background-position: center;
                    margin-left: 8px;
                    margin-right: 8px;
                }
            }
        }
    }
    
    .region {
        width: 75px;
        height: 75px;

        box-shadow: 0px 0px 4px 1px rgb(0 0 0 / 4%);
        border-radius: 1px;
        border: 2px solid #E7E7E7;
        background: #F5F5F5;

        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding: 6px;
        gap: 6px;

        transform-origin: center center;
        transition: transform 0.2s ease-in-out;
        cursor: default;
        &:hover {
            transform: scale(1.4);
        }

        .regionName,
        .regionValue,
        .regionSubvalue {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 3px;
            white-space: nowrap;
        }

        .regionName {
            @include font(14px, 400, 120%);
        }

        .regionValue {
            @include font(16px, 700, 120%);
        }

        .regionSubvalue {
            @include font(14px, 400, 120%);

            display: flex;
            align-items: center;
            gap: 3px;

            .arrow {
                width: 12px;
                height: 15px;

                background-position: center;
                background-repeat: no-repeat;

                &.arrowUp {
                    background: url('./arrowUp.svg') no-repeat;
                }

                &.arrowDown {
                    background: url('./arrowDown.svg') no-repeat;
                }
            }
        }
    }
}


:global .js-screenshot-processing :local {
    .region {
        box-shadow: none;
    }
}