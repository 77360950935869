@import '../../../uikit/colors.scss';
@import '../../../uikit/mixins.scss';

@keyframes appearanceFromTopAndFromOpacity {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.container {
    display: contents;
}

.tooltip {
    @include font(12px, 400);
    padding: 6px 16px;
    max-width: 300px;

    background: rgba(53, 53, 53, 0.9);
    color: $black-0;
    border-radius: 12px;
    z-index: 2450;

    white-space: pre-wrap;

    animation: appearanceFromTopAndFromOpacity 0.15s ease-in-out;
}