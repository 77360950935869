@import '../../../../../../uikit/mixins.scss';

.container {
    display: flex;
    flex-direction: row;

    @include mobile {
        flex-direction: column;
        height: 100%;
    }
}