@import '../../uikit/colors.scss';
@import '../../uikit/mixins.scss';

.wrapper {
    z-index: 2500;
    position: fixed;
    
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.25);
}

.modal {
    position: relative;
    background-color: $black-0;
    padding: 32px;
    @include border-radius();
    min-width: 576px;
    max-width: 100vw;

    @include small {
        width: calc(100vw - 32px);
        max-height: calc(100vh - 32px);
        max-height: calc(100dvh - 32px);

        min-width: auto;
        min-height: auto;
        padding: 16px;
        border-radius: 8px;
    }

    .title {
        @include font(22px, 700);
        margin-bottom: 4px;
        &.gap {
            margin-bottom: 16px;
        }

        @include small {
            max-width: calc(100% - 57px);
        }

        display: flex;
        align-items: center;
        flex-direction: column;

        &.left {
            align-items: flex-start;
        }

        &.sub {
            @include font(16px, 400);
        }
    }

    .body {
        margin-bottom: 16px;
    }

    .footer {
        display: flex;
        justify-content: stretch;
    }
}

.body {
    max-width: 576px;

    &.center {
        text-align: center;
    }
}

.close {
    position: absolute;
    top: 12px;
    right: 12px;
    width: 24px;
    height: 24px;
    cursor: pointer;
    background-image: url('./close.svg');
    background-size: 24px 24px;
    background-repeat: no-repeat;
}