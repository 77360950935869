@import '../../../../../../../uikit/colors.scss';

.tools {
    display: flex;
    flex-direction: column;
    gap: 4px;
    margin-top: 10px;

    .tool {
        display: flex;
        align-items: center;
        gap: 16px;
        flex-direction: row;
        padding: 8px 0;

        background-color: transparent;
        border-radius: 8px;
        transition: all 0.2s ease-in-out;
        cursor: pointer;
        text-decoration: none;

        &:hover {
            background-color: rgba(240, 240, 240, 0.25);
        }
    }

    .toolIcon {
        flex: 0 0 40px;
        width: 40px;
        height: 40px;

        background: rgba(240, 240, 240, 0.5);
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .toolTitle {
        font-size: 16px;
        color: $black-900;
        line-height: 20px;
    }
}