@import '../../uikit/colors.scss';
@import '../../uikit/mixins.scss';

.content {
    max-height: 500px;
    margin: 16px 0;
    @include scrollbars();
    @include font(16px, 400);
    position: relative;
    width: 600px;
    max-width: 100vw;
}