@import '../../../uikit/mixins.scss';

.typeTag {
    padding: 2px 8px;
    height: 24px;

    align-items: center;
    justify-content: center;

    display: inline-flex;
    border-radius: 20px;

    @include font(14px, 400, 14px);
}