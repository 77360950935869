@import '../../../uikit/colors.scss';
@import '../../../uikit/mixins.scss';

.contacts {
    padding-left: 20px;
    padding-right: 20px;
    .title {
        @include font(18px, 700);
        margin-bottom: 20px;
    }

    .contact {
        margin-bottom: 20px;
    }

    .contactInfo {
        .contactTitle {
            @include font(16px, 500);
            color: #2A2536;
        }

        .contactRole {
            @include font(16px, 400);
            color: #2A2536;
            opacity: 0.3;
            margin-bottom: 6px;
        }

        .contactEmail {
            @include font(16px, 400);
            color: #2A2536;
            display: flex;
            align-items: center;

            &.copiable {
                cursor: pointer;
            }
        }
    }
}

.support {
    background-color: $black-0;

    flex: 1 0 auto;

    display: flex;
    flex-direction: row;
    gap: 20px;

    @media (max-width: 1024px) {
        flex-direction: column-reverse;
    }

    .divider {
        height: 1px;
        background-color: $black-200;
        margin: 20px 0;
    }

    .leftBar {
        flex: 0 0 350px;
        border-right: 1px solid $black-200;

        max-height: calc(100vh - 64px);
        max-height: calc(100dvh - 64px);
        @include scrollbars();

        .section {
            padding-right: 16px;
            display: flex;
            flex-direction: column;

            .title {
                @include font(16px, 700);
                display: flex;
                align-items: center;
                flex-direction: row;
                gap: 6px;

                .titleName {
                    @include font(16px, 700);
                }

                .expandIcon {
                    transform: rotate(-90deg);
                    @include fastTransition(transform);

                    &.expanded {
                        transform: rotate(0deg);
                    }
                }
            }
        }
    }

    .link, .subLink, .subSubLink, .section .title {
        @include font(16px, 400);
        cursor: pointer;
        @include transition(left);

        padding: 6px 12px;
        border-radius: 2px;
        
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &:hover {
            background-color: $black-200;
        }

        &.active {
            background-color: $primary;
        }
    }

    .link {
        padding-left: 45px;
    }

    .subLink,
    .subSubLink {
        color: $black-900;
        margin-left: 20px;
        text-decoration: underline;
    }

    .subSubLink {
        margin-left: 40px;
    }

    .contents {
        background: rgba(242, 242, 242, 0.6);
        width: 1000px;
        margin-top: 20px;
    }

    .content {
        flex: 1 1 auto;
        padding: 0 20px 20px 20px;
        width: 100%;
        overflow-y: auto;

        display: flex;
        flex-direction: column;
        align-items: center;

        .text {
            width: 1000px;
            max-width: 100%;
            margin-top: 20px;
        }

        max-height: calc(100vh - 64px);
        position: relative;
        @include scrollbars();

        @media (max-width: 1024px) {
            padding-left: 0;
            max-height: 50vh;
        }

        .hidden {
            visibility: hidden;
        }
    }
}
